<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            v-model="supervisores"
            :headers="headers"
            export-pdf-xlsx
            :slots="['item.telefones']"
            :actions="actions"
            :show-expand="true"
            :permission="'list_supervisor'"
            :sort-by="['nome']"
            :sort-desc="[false]"
          >
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.unidades"
                :colspan="headers.length"
              >
                <div>
                  <v-simple-table
                    dense
                    class="mb-5 mt-5"
                    style="border: 1px solid lightgrey; max-width: 85%;"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            colspan="3"
                            style="text-align: center;"
                          >
                            Unidades de negócio
                          </th>
                        </tr>
                        <tr>
                          <th>
                            CNPJ
                          </th>
                          <th>
                            Nome Fantasia
                          </th>
                          <th>
                            Razão Social
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="unidades in item.unidades"
                          :key="unidades.nome_fantasia"
                        >
                          <td>{{ unidades.cnpj }}</td>
                          <td>{{ unidades.nome_fantasia }}</td>
                          <td>{{ unidades.razao_social }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </td>
            </template>
            <template
              v-if="$store.getters.hasPermission('inserir_supervisor')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar novo Supervisor"
                  rounded
                  @click="$router.push({ path: 'supervisores/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import SupervisorStore, { BOOTSTRAP, DELETE_SUPERVISOR } from '@/store/modules/supervisores'
  import { mapState, mapActions } from 'vuex'
  import store from '@/store'
  import { sortDateTime } from '../../utils/sorts'
  export default {
    data () {
      return {
        search: '',
        actions: [
          {
            title: 'Editar Supervisor',
            color: 'info darken-3',
            click: item => this.editarSupervisor(item),
            permission: 'editar_supervisor',
            icon: 'mdi-pencil',
          },
          {
            title: 'Excluir Supervisor',
            color: 'red darken-3',
            click: item => this.DELETE_SUPERVISOR(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_supervisor',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'data-table-expand', sortable: false, groupable: false, width: '10px' },
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, text: 'CPF', value: 'cpf', width: 'auto', filterable: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Nome', value: 'nome', width: 'auto', filterable: true, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true, styleXLSX: { type: 'telefone' } },
          { align: 'left', sortable: false, groupable: false, text: 'RG', value: 'rg', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, text: 'Papel', value: 'roles.description', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, filterable: true, text: 'Gerente', value: 'gestor.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, text: 'Email', value: 'email', width: 'auto', filterable: true, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Data de Nascimento', value: 'data_nascimento', width: 'auto', sort: sortDateTime },
          { align: 'left', sortable: true, groupable: true, text: 'Sexo', value: 'sexo', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('supervisores', ['ready', 'supervisores']),
    },
    async created () {
      if (!this.$store.hasModule('supervisores')) { this.$store.registerModule('supervisores', SupervisorStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('supervisores', [BOOTSTRAP, DELETE_SUPERVISOR]),
      editarSupervisor ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/supervisores/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
